import Logo from '../../components/Logo';
import lockup from '../../assets/images/s23/s23-fsb-watch-lockup.svg'
import lockupMobile from '../../assets/images/s23/s23-fsb-watch-lockup-mobile.svg'

const PageTitle=()=> {
    return <div className="page-title">
        <div className='title-text-container'>
            <span>cabi’s fall 2024</span><br></br>fashion show
        </div>
        <Logo variant='light'/>
    </div>
}

export default PageTitle