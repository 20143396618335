import React from "react";
import "./assets/styles/main.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoon from "./containers/ComingSoon";
import DayOfShow from "./containers/DayOfShow";
import VirtualScoop from "./containers/VirtualScoop";
import Watch from "./containers/Watch";
function App() {
  return (
    <main className={process.env.REACT_APP_ENVIRONMENT}>
      <Router>
        <Routes>
          <Route exact path="/day-of-show" element={<DayOfShow />} />
          <Route exact path="/watch" element={<Watch />} />
          <Route exact path="/post-watch" element={<VirtualScoop />} />
          <Route exact path="/coming-soon" element={<ComingSoon />} />   
          <Route exact path="/" element={<DayOfShow />} />
          <Route path="*" element={<DayOfShow />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;
