import React from "react";
import Typography from "../../components/Typography";
// import useWindowDimensions from "../../Utilities/useWindowDimensions";
// import titleLogo from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy.svg"
// import titleLogoMobile from "../../assets/images/s23/cabi’s-Spring-2023-F-Copy-mobile.svg"
const IntroText = ({success}) => {
  // const { width } = useWindowDimensions();
  // let isMobile = false
  // if(width<480){
  //   isMobile = true
  // } 
  return (
    <>
      <Typography variant="h1">
        COMING SOON
      </Typography>
      <h2>
        <span>cabi’s</span> Fall 2024<br></br>Fashion Show
      </h2>
      <br />
      <Typography variant="h3">
        FRIDAY, JULY 19, 2024
        <br />
        3:00 p.m. Pacific | 6:00 p.m. Eastern | 11:00 p.m. U.K.
      </Typography>
      <div className="contain-tablet-down">
        {success? (<> </>) :
          (
            <>   
            <Typography variant="h4">
            <span>Mark your calendar! The Fall 2024 Collection is making its runway debut. Register now to watch the live stream on July 19.</span><span>Contact your Stylist for the exclusive access code.</span>
            </Typography>
            </>
          )
        }
        {!success && <Typography variant="h4">
          By registering, you are opting in to receive exclusive emails from cabi.{" "}
          {/* <a href="https://www.cabionline.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a> */}
        </Typography>}
      </div>
    </>
  );
};

export default IntroText;
